import React from 'react';
import { IconProps } from '@/icons/types';

const SizeFit = ({ height = 20, width = 20, ...props }: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width={width} height={height} fill="none" {...props}>
        <path
            d="M13.333 17.5H6.667a.833.833 0 0 1-.834-.833v-6.609l-1.083.875a.845.845 0 0 1-1.175 0L1.217 8.575a.83.83 0 0 1 0-1.175l4.9-4.9H7.5c0 .917 1.117 1.667 2.5 1.667s2.5-.75 2.5-1.667h1.383l4.9 4.9a.83.83 0 0 1 0 1.175l-2.358 2.358a.845.845 0 0 1-1.175 0l-1.083-.875v6.609a.833.833 0 0 1-.834.833Zm3.684-9.517L13.425 4.4c-.258.292-.567.55-.925.767-.7.416-1.558.666-2.5.666-1.417 0-2.675-.566-3.425-1.433L2.983 7.983l1.184 1.184 2.5-1.667H7.5v8.333h5V7.5h.833l2.5 1.667 1.184-1.184Z"
            fill="#000"
        />
    </svg>
);

export default SizeFit;
