import { useContext, useEffect, useRef } from 'react';
import { IUseProductCollapseSection } from '../types';
import { ProductDetailsSectionContext } from '@/components/RootComponents/Product/modules/ProductDetailsSection/context/productDetailsSectionContext';

interface IUseProductCollapseSectionProps {
    index: number;
    isOpenByDefault?: boolean;
}

export const useProductCollapseSection = ({
    index,
    isOpenByDefault,
}: IUseProductCollapseSectionProps): IUseProductCollapseSection => {
    const { activeIndex, setActiveIndex } = useContext(ProductDetailsSectionContext);
    const collapseSectionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpenByDefault && activeIndex === -1) {
            setActiveIndex(index);
        }
    }, []);

    useEffect(() => {
        if (activeIndex === index && !isOpenByDefault) {
            collapseSectionRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [activeIndex, index, collapseSectionRef, isOpenByDefault]);

    const handleSectionClick = () => setActiveIndex(activeIndex !== index ? index : -1);

    return {
        collapseSectionRef,
        handleSectionClick,
    };
};
