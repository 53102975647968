'use client';

import React, { useContext } from 'react';
import Container from '@/components/Container';
import { Typography } from '@vaimo-int/aem-pwa-common-components';
import Link from '@/components/Link';
import { useSuspenseQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { skipToken } from '@apollo/client';
import { getBreadCrumbsForCategory } from '@/components/Breadcrumbs/api/breadcrumbsPdp.gql';
import { getBreadcrumbs } from '@/components/Breadcrumbs/hooks/getBreadcrumbs';
import { useResourceUrl } from '@/hooks/useResourceUrl';
import { LAST_CATEGORY_URL_KEY } from '@/components/Breadcrumbs/utils/const';
import { removeFirstSlashFromRelativeUrl } from '@/utils/url';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';
import classes from '@/components/Breadcrumbs/breadcrumbs.module.scss';

/**
 * Component is CSR only. See: HHSSR-428
 */

const BreadcrumbsCsrPdp = () => {
    const { breadCrumbsData, name } = useContext(ProductContext);
    const lastCategoryUrl = globalThis.sessionStorage.getItem(LAST_CATEGORY_URL_KEY);
    const isLastCategoryAllowed =
        lastCategoryUrl && breadCrumbsData.allowedParents?.includes(removeFirstSlashFromRelativeUrl(lastCategoryUrl));
    const resourceUrl = useResourceUrl();

    const { data } = useSuspenseQuery<any>(
        getBreadCrumbsForCategory,
        isLastCategoryAllowed
            ? {
                  context: {
                      skipErrorToasts: true,
                  },
                  fetchPolicy: 'cache-and-network',
                  variables: {
                      url: removeFirstSlashFromRelativeUrl(lastCategoryUrl),
                  },
              }
            : skipToken,
    );

    const items = getBreadcrumbs({
        categoryData: data?.categories?.items[0],
        categoryUrlSuffix: breadCrumbsData.categoryUrlSuffix,
        currentProduct: name,
    });

    const productBreadcrumbsLinks = items?.length
        ? items.map(({ path, text }, index) => {
              if (items.length === 1) {
                  return (
                      <li key={text} className={classes.lastCrumbText} data-mt-type="breadcrumbs-list-item">
                          <Typography.Body style={'basic'} className={classes.productText}>
                              {text}
                          </Typography.Body>
                      </li>
                  );
              }
              if (path) {
                  return (
                      <li
                          key={text}
                          className={
                              index + 1 < items.length && !items[index + 1].path
                                  ? classes.lastCrumbText
                                  : classes.productText
                          }
                          data-mt-type="breadcrumbs-list-item"
                      >
                          <Typography.Body style={'basic'} className={classes.productText}>
                              <Link href={resourceUrl(path)} className={classes.productLink}>
                                  {text}
                              </Link>
                          </Typography.Body>
                      </li>
                  );
              }

              return null;
          })
        : null;

    return (
        <div className={classes.productBreadcrumbsContainer} data-mt-type="breadcrumbs-container">
            <Container className={classes.productBreadcrumbsContainerWrap}>
                <ul className={classes.productBreadcrumbsRoot} data-mt-type="breadcrumbs-list">
                    {productBreadcrumbsLinks}
                </ul>
            </Container>
        </div>
    );
};

export default BreadcrumbsCsrPdp;
