import { gql } from '@apollo/client';

export const addProductToWishlistMutation = gql`
    mutation AddProductToWishlistFromGallery($wishlistId: ID!, $itemOptions: WishlistItemInput!) {
        addProductsToWishlist(wishlistId: $wishlistId, wishlistItems: [$itemOptions]) {
            user_errors {
                code
                message
            }
        }
    }
`;

export const removeProductFromWishlistMutation = gql`
    mutation RemoveProductsFromWishlistGallery($wishlistId: ID!, $wishlistItemsIds: ID!) {
        removeProductsFromWishlist(wishlistId: $wishlistId, wishlistItemsIds: [$wishlistItemsIds]) {
            user_errors {
                code
                message
            }
        }
    }
`;

export const getProductsInWishlistsQuery = gql`
    query GetProductsInWishlistsForGallery {
        customerWishlistProducts @client
    }
`;

export const getCustomerWishlistQuery = gql`
    query GetCustomerWishlist {
        customer {
            wishlists {
                id
                items_count
                items_v2 {
                    items {
                        id
                        product {
                            sku
                        }
                    }
                }
            }
        }
    }
`;
