'use client';

import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAppContext } from '@/lib/context';
import OmnipresentAddToCart from '@/components/OmnipresentAddToCart';
import { useCurrentStatus } from '@/talons/CurrentStatus/useCurrentStatus';
import clsx from 'clsx';
import { Button2 } from '@vaimo-int/aem-pwa-common-components';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';
import classes from './addToCartButton.module.scss';

interface IAddToCartButtonProps {
    isShoppableElement: boolean;
}

const AddToCartButton = ({ isShoppableElement }: IAddToCartButtonProps): ReactElement | null => {
    const {
        handleAddToCart,
        handleShakeAnimation,
        isAddToCartDisabled: shouldDisableActions,
        noSizeSelected,
        shouldShakeButton,
    } = useContext(ProductContext);
    const [{ addToCartActionStatus }] = useAppContext();
    const [isAddToCartButtonInView, setAddToCartButtonInView] = useState<boolean>(false);

    const message = useCurrentStatus({ status: addToCartActionStatus });

    const { inView, ref: addToCartButtonInViewRef } = useInView({
        threshold: 0,
    });

    useEffect(() => {
        // SSR workaround for proper hydration, temporary solution
        setAddToCartButtonInView(inView);
    }, [inView]);

    if (!isShoppableElement) {
        return null;
    }

    return (
        <div
            className={clsx(classes.productInfoAddToCart, {
                [classes.shakeAnimation]: shouldShakeButton,
            })}
            onClick={() => {
                shouldDisableActions && handleShakeAnimation();
            }}
        >
            <div ref={addToCartButtonInViewRef}>
                <Button2
                    onClick={handleAddToCart}
                    disabled={shouldDisableActions}
                    isFullWidth={true}
                    aria-disabled={shouldDisableActions}
                    aria-label={message}
                    role="button"
                    tabIndex={0}
                    variant={'primary'}
                    title={message}
                    classes={clsx(classes.addToCartButton, { [classes.addToCartButtonInactive]: noSizeSelected })}
                >
                    {message}
                </Button2>
            </div>
            <OmnipresentAddToCart isAddToCartButtonInView={isAddToCartButtonInView}>
                <Button2
                    onClick={handleAddToCart}
                    disabled={shouldDisableActions}
                    role="button"
                    aria-disabled={shouldDisableActions}
                    aria-label={message}
                    isFullWidth={true}
                    tabIndex={-1}
                    title={message}
                    classes={clsx(classes.omnipresentButton, { [classes.addToCartButtonInactive]: noSizeSelected })}
                >
                    {message}
                </Button2>
            </OmnipresentAddToCart>
        </div>
    );
};

export default AddToCartButton;
