export type ConfigurableAttributeOption = {
    __typename: 'ConfigurableAttributeOption';
    code: 'color' | 'size';
    color_group: string;
    value_index: number;
};

export type Money = {
    __typename: 'Money';
    currency: string;
    value: number;
};

export type Discount = {
    __typename: 'Discount';
    amount: Money;
    default_label: string;
    label: string;
};

export type DiscountBreakdown = {
    __typename: 'DiscountBreakdown';
    amount: string;
    label: string;
    simple_action: string;
};

export type ProductDiscount = {
    __typename: 'ProductDiscount';
    amount_off: number;
};

export type ProductPrice = {
    __typename: 'ProductPrice';
    discount: ProductDiscount;
    final_price: Money;
    regular_price: Money;
};

export type PriceRange = {
    __typename: 'PriceRange';
    maximum_price: ProductPrice;
    minimum_price: ProductPrice;
};

export type ProductImage = {
    __typename: 'ProductImage';
    url: string;
};

export type Price = {
    __typename: 'Price';
    amount: Money;
};

export type LowestPrice = {
    __typename: 'LowestPrice';
    campaign_content: string;
    campaign_title: string;
    lowest_price: Money;
};

export type ProductPrices = {
    __typename: 'ProductPrices';
    regularPrice: Price;
};

export type ProductStockStatus = 'IN_STOCK' | 'OUT_OF_STOCK';

export enum MediaGalleryTypeEnum {
    IMAGE = 'image',
    VIDEO = 'external-video',
}

export interface MediaGallery {
    url: string;
}

export type MediaGalleryEntry = {
    __typename: 'MediaGalleryEntry';
    disabled: boolean;
    file: string;
    id: number;
    label: null | string;
    media_type: MediaGalleryTypeEnum;
    position: number;
    uid: string;
    video_content: null | ProductMediaGalleryEntriesVideoContent;
};

export type ProductMediaGalleryEntriesVideoContent = {
    __typename: 'ProductMediaGalleryEntriesVideoContent';
    media_type: 'external-video';
    video_description: string;
    video_metadata: string;
    video_provider: '';
    video_title: string;
    video_url: string;
};

export type SimpleProduct = {
    __typename: 'SimpleProduct';
    id: number;
    lowest_price_data: LowestPrice | null;
    media_gallery: MediaGallery[];
    media_gallery_entries: MediaGalleryEntry[];
    only_x_left_in_stock: null | number;
    price_range: PriceRange;
    sku: string;
    special_price: null | number;
    stock_status: ProductStockStatus;
    thumbnail: {
        url: string;
    };
    uid: string;
};

export type ProductUseForActivity = {
    __typename: 'ProductUseForActivity';
    code: string;
    title: string;
};

export type PerformanceData = null | {
    __typename: 'PerformanceData';
    label: string;
    value: number;
};

export type Breadcrumb = {
    __typename: 'Breadcrumb';
    category_id: number;
    category_name: string;
};

export type CategoryTree = {
    __typename: 'CategoryTree';
    breadcrumbs: Breadcrumb[];
    id: number;
    name: string;
    uid: string;
    url_path: string;
    use_as_related_category: 1 | 0;
};

export type ComplexTextValue = {
    __typename: 'ComplexTextValue';
    html: string;
};

export type ImageSwatchData = {
    __typename: 'ImageSwatchData';
    thumbnail: string;
    value: string;
};
export type ConfigurableProductOptionsValues = {
    __typename: 'ConfigurableProductOptionsValues';
    default_label: string;
    label: string;
    store_label: string;
    swatch_data: ImageSwatchData;
    uid: string;
    use_default_value: true;
    value_index: number;
};

export type ConfigurableProductOptions = {
    __typename: 'ConfigurableProductOptions';
    attribute_code: string;
    attribute_id: string;
    color: string;
    configurable_product_option_uid: string;
    configurable_product_option_value_uid: string;
    id: number;
    label: string;
    option_label: string;
    uid: string;
    value_id: number;
    value_label: string;
    values: ConfigurableProductOptionsValues[];
};

export type ConfigurableProduct = ConfigurableProductSwatches & {
    __typename: 'ConfigurableProduct';
    care_instructions?: {
        html: string;
    };
    categories: CategoryTree[];
    content_links?: {
        html: string;
    };
    default_category?: string | number;
    depth?: string | null;
    description?: {
        html: string;
    };
    features: string;
    fiber?: {
        html: string;
    };
    hh_product_type?: string | null;
    id: number;
    image: {
        label: string;
        url: string;
    };
    is_fit_analytics_enabled: boolean;
    is_size_guide_enabled: boolean;
    length?: string | null;
    meta_description: null | string;
    meta_keyword: null | string;
    meta_title: null | string;
    name: string;
    page_title: string;
    pdf_sheet: string;
    performance_breathability: PerformanceData;
    performance_durability: PerformanceData;
    performance_insulation: PerformanceData;
    performance_waterproof: PerformanceData;
    performance_weight: PerformanceData;
    performance_windproof: PerformanceData;
    price: ProductPrices;
    price_range: PriceRange;
    product_use_for: ProductUseForActivity[];
    product_workwear_features?: string;
    robots_meta_tag: null | string;
    short_description: ComplexTextValue;
    sizechart?: string | null;
    sku: string;
    small_image: {
        url: string;
    };
    stock_status: 'IN_STOCK' | 'OUT_OF_STOCK';
    uid: string;
    url_key: string;
    volume?: string | null;
    water_column?: string;
    weight_with_unit?: string;
    width?: string | null;
};

export type ConfigurableProductSwatches = {
    __typename: 'ConfigurableProduct';
    configurable_options: ConfigurableProductOptions[];
    id: number;
    media_gallery_entries?: MediaGalleryEntry[];
    name?: string;
    price_range?: PriceRange;
    sku?: string;
    uid: string;
    variants: ConfigurableVariant[];
};

export type ConfigurableVariant = {
    __typename: 'ConfigurableVariant';
    attributes: ConfigurableAttributeOption[];
    product: SimpleProduct;
};

export type ConfigurableCartItemPrices = {
    __typename: 'CartItemPrices';
    final_price: Money;
    row_total: Money;
    row_total_including_tax: Money;
    total_item_discount: Money;
};

export type ConfigurableCartProduct = {
    __typename: 'ConfigurableProduct';
    bm_category?: string;
    categories: {
        __typename: 'CategoryTree';
        id: number;
        name: string;
        uid: string;
    }[];
    configurable_options: ConfigurableProductOptions[];
    id: number;
    name: string;
    price: { regularPrice: { amount: Money } };
    price_range: {
        __typename: 'PriceRange';
        minimum_price: {
            __typename: 'ProductPrice';
            final_price: Money;
            regular_price: Money;
        };
    };
    small_image: { url: string };
    stock_status: ProductStockStatus;
    thumbnail: ProductImage;
    url_key: string;
    url_suffix: string;
    variants: {
        __typename: 'ConfigurableVariant';
        attributes: {
            __typename: 'ConfigurableAttributeOption';
            uid: string;
        }[];
        product: {
            __typename: 'SimpleProduct';
            id: number;
            small_image: { url: string };
            thumbnail: ProductImage;
        };
    }[];
};

export type ConfigurableCartItem = {
    __typename: 'ConfigurableCartItem';
    configurable_options: ConfigurableProductOptions[];
    configured_variant: {
        __typename: 'SimpleProduct';
        id: number;
        sku: string;
        small_image: {
            url: string;
        };
        uid: string;
    };
    id: string;
    prices: ConfigurableCartItemPrices;
    product: ConfigurableCartProduct;
    quantity: number;
    uid: string;
};

export type ProductSwatchOptions = {
    option_label: string;
    option_value: string;
};

export type SwatchOptions = {
    optionCodes: Map<string, 'color' | 'size'>;
    optionSelections: Map<string, number | undefined>;
};

export type SwatchOptionsWithVariants = SwatchOptions & {
    variants: ConfigurableVariant[];
};

export type TProductPrice = {
    currency: string;
    hasDiscount: boolean;
    maxPrice: number;
    minPrice: number;
    value: number;
};
