'use client';

import React, { ReactElement, useContext } from 'react';
import { FiChevronRight as ChevronRightIcon } from 'react-icons/fi';
import { Typography } from '@vaimo-int/aem-pwa-common-components';
import { useProductCollapseSection } from './hooks/useProductCollapseSection';
import type { IUseProductCollapseSection } from './types';
import clsx from 'clsx';
import { ProductDetailsSectionContext } from '../ProductDetailsSection/context/productDetailsSectionContext';
import type { IProductCollapseSectionProps } from './types';
import classes from './productCollapseSection.module.scss';

const ProductCollapseSection = ({
    children,
    className,
    dataCustomId,
    heading,
    index,
    isOpenByDefault = false,
}: IProductCollapseSectionProps): ReactElement => {
    const { activeIndex } = useContext(ProductDetailsSectionContext);
    const { collapseSectionRef, handleSectionClick }: IUseProductCollapseSection = useProductCollapseSection({
        index,
        isOpenByDefault,
    });

    return (
        <div
            className={clsx(
                classes.productCollapseSection,
                activeIndex === index && classes.productCollapseSectionIsActive,
                className,
            )}
            data-custom-id={dataCustomId}
            ref={collapseSectionRef}
        >
            <div className={classes.productCollapseSectionTitle} onClick={handleSectionClick}>
                {heading && (
                    <Typography.Heading style={'h3'} tag={'h2'}>
                        {heading}
                    </Typography.Heading>
                )}
                <span className={classes.productCollapseSectionTitleIcon}>
                    <ChevronRightIcon />
                </span>
            </div>
            <div className={classes.productCollapseSectionContent}>{children}</div>
        </div>
    );
};

export default ProductCollapseSection;
