'use client';

import React, { useContext } from 'react';
import ProductPrice from '@/components/ProductPrice';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';

const ProductPriceWrapper = ({ classesProductPrice }: { classesProductPrice: string }) => {
    const { productPrice } = useContext(ProductContext);

    return <ProductPrice {...productPrice} className={classesProductPrice} />;
};

export default ProductPriceWrapper;
