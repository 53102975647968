import { gql } from '@apollo/client';
import { CategoryBreadcrumbsFragment } from '@/components//RootComponents/Category/api/categoryFragments.gql';

export const getBreadCrumbsForCategory = gql`
    query GetBreadCrumbsForCategory($url: String!) {
        categories(filters: { url_path: { eq: $url } }) {
            items {
                uid
                id
                name
                url_path
                ...CategoryBreadcrumbsFragment
            }
        }
    }
    ${CategoryBreadcrumbsFragment}
`;
