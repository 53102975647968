import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { useMutation } from '@apollo/client';
import { useAddToWishlist as useGtmAddToWishlist } from '@vaimo/google-tag-manager/src/hooks';
import { useUserContext } from '@/lib/context';

import {
    addProductToWishlistMutation,
    getCustomerWishlistQuery,
    removeProductFromWishlistMutation,
} from '../api/addToListButton.gql';
import { useTranslations } from 'next-intl';
import { toastApi } from '@/lib/context/toasts';

export const useAddToListButton = ({ item, itemName }) => {
    const tWishlistButton = useTranslations('wishlistButton');
    const tWishlist = useTranslations('wishlist');
    const [{ isSignedIn }] = useUserContext();

    const [addProductToWishlist, { loading: isAddingToWishlist }] = useMutation(addProductToWishlistMutation);

    const [removeProductFromWishlist, { loading: isRemovingFromWishlist }] = useMutation(
        removeProductFromWishlistMutation,
    );

    const { data: customerWishlists } = useQuery(getCustomerWishlistQuery, {
        skip: !isSignedIn,
    });
    const customerWishlistProducts = customerWishlists?.customer.wishlists[0] || null;
    const currentWishlistItem = customerWishlistProducts?.items_v2.items.find((el) => el.product.sku === item.sku);

    const [isSelected, setIsSelected] = useState(false);
    const [isConfirmingModalOpen, setIsConfirmingModalOpen] = useState(false);
    const [addedProductSku, setAddedProductSku] = useState(null);

    useEffect(() => {
        if (customerWishlistProducts) {
            setIsSelected(customerWishlistProducts.items_v2.items.some((i) => i.product.sku === item.sku));
        }
    }, [customerWishlistProducts, item.sku]);

    const messageVariant = [
        {
            content: tWishlist('loginMessage'),
            name: 'error_login',
        },
        {
            content: tWishlist('successMessageGeneral', { itemName }),
            name: 'add_item',
        },
        {
            content: tWishlist('successRemoveMessageGeneral', { itemName }),
            name: 'remove_item',
        },
        {
            content: tWishlist('addError'),
            name: 'error',
        },
    ];

    const notificationHelper = useCallback(
        (variant, nameMsg) => {
            const { content } = messageVariant.find((msg) => msg.name === nameMsg);

            toastApi.add({
                dismissible: true,
                message: content,
                variant,
            });
        },
        [itemName],
    );

    const addToWishlist = useCallback(async () => {
        const response = await addProductToWishlist({
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getCustomerWishlistQuery,
                },
            ],
            variables: { itemOptions: item, wishlistId: '0' },
        });

        if (response.data.addProductsToWishlist.user_errors.length) {
            await notificationHelper('error', 'error');
        } else {
            await notificationHelper('success', 'add_item');
            setAddedProductSku(item.sku);
        }
    }, []);

    const removeFromWishlist = useCallback(async () => {
        const response = await removeProductFromWishlist({
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: getCustomerWishlistQuery,
                },
            ],
            variables: {
                wishlistId: customerWishlistProducts.id,
                wishlistItemsIds: currentWishlistItem.id,
            },
        });

        if (response.data.removeProductsFromWishlist.user_errors.length) {
            await notificationHelper('error', 'error');
        } else {
            await notificationHelper('success', 'remove_item');
            setAddedProductSku(null);
        }
    }, [
        currentWishlistItem,
        customerWishlistProducts,
        notificationHelper,
        getCustomerWishlistQuery,
        removeProductFromWishlist,
    ]);

    const handleClick = useCallback(
        async (event) => {
            event.currentTarget.blur();
            if (!isSignedIn) {
                setIsConfirmingModalOpen(true);

                return;
            }
            try {
                if (isSelected) {
                    await removeFromWishlist();
                } else {
                    await addToWishlist();
                }
            } catch (error) {
                await notificationHelper('error', 'error');
            }
        },
        [][(isSignedIn, isSelected, removeFromWishlist, addToWishlist, notificationHelper)],
    );

    useEffect(() => {
        const addToWishListAfterSignIn = async () => {
            const data = sessionStorage.getItem('actionAfterLogin') || '';
            const { action } = data && JSON.parse(data);

            if (
                action === 'addToWishlist' &&
                isSignedIn &&
                item?.sku &&
                item?.selected_options?.length &&
                !!customerWishlistProducts
            ) {
                const checkIsSelected = customerWishlistProducts.items_v2.items.some((i) => i.product.sku === item.sku);

                try {
                    if (!checkIsSelected) {
                        await addToWishlist();
                    }
                } catch (e) {
                    await notificationHelper('error', 'error');
                } finally {
                    sessionStorage.removeItem('actionAfterLogin');
                }
            }
        };

        addToWishListAfterSignIn();
    }, [isSignedIn, item?.sku, customerWishlistProducts]);

    const titleButton = isSelected ? tWishlistButton('removeFromWishlistText') : tWishlistButton('addToWishlistText');

    const buttonProps = {
        onClick: handleClick,
        title: titleButton,
        type: 'button',
    };

    useGtmAddToWishlist({ isAddedToWishlist: Boolean(addedProductSku) });

    return {
        buttonProps,
        handleClick,
        isAddingToWishlist,
        isConfirmingModalOpen,
        isRemovingFromWishlist,
        isSelected,
        isSignedIn,
        isWishlistButtonDisabled: isAddingToWishlist || isRemovingFromWishlist,
        setIsConfirmingModalOpen,
    };
};
