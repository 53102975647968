const VIDEO_RESOLUTION = {
    height: 450,
    width: 900,
};

export const getVideoLinkFromMetaData = (metadata?: string): string => {
    if (!metadata) return '';
    try {
        const parsedMetadata = JSON.parse(metadata);

        const videoLink = parsedMetadata.files.find(
            (file: any) => file.width === VIDEO_RESOLUTION.width && file.height === VIDEO_RESOLUTION.height,
        )?.link;

        return videoLink || parsedMetadata.files[0].link;
    } catch (e) {
        console.error(e);

        return '';
    }
};
