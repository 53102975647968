'use client';

import React, { ReactElement, ReactNode } from 'react';
import useOmnipresentAddToCart from './hooks/useOmnipresentAddToCart';
import defaultClasses from './omnipresentAddToCart.module.scss';

// This component can be extracted and used in other projects if correct props are supplied.
// We definitely need to specify a correct point to which our window scrolls upon clicking the omnipresent segment
// This is supplied as a ref element - product name in our case, so we can see entire product details segment.

interface OmnipresentAddToCartProps {
    children?: ReactNode;
    isAddToCartButtonInView: boolean;
}

const OmnipresentAddToCart = ({
    children,
    isAddToCartButtonInView,
}: OmnipresentAddToCartProps): ReactElement | null => {
    const { executeScroll } = useOmnipresentAddToCart();

    if (isAddToCartButtonInView) {
        return null;
    }

    return (
        <section
            className={defaultClasses.topPanelWrapper}
            onClick={executeScroll}
            data-mt-type="omnipresent-add-to-cart"
        >
            {children}
        </section>
    );
};

export default OmnipresentAddToCart;
