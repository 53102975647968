'use client';

import React, { useContext, useEffect, useState } from 'react';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';
import YotpoReviewWidget from '@/integrations/yotpo/src/components/YotpoReviewWidget';

interface IYotpoReviewWidgetWrapper {
    imageUrl: string;
    productId: number;
    productName: string;
}

const YotpoReviewWidgetWrapper = ({ imageUrl, productId, productName }: IYotpoReviewWidgetWrapper) => {
    const { productPrice } = useContext(ProductContext);
    const [productUrl, setProductUrl] = useState<string>();

    useEffect(() => {
        setProductUrl(globalThis.location.href);
    }, []);

    return (
        <YotpoReviewWidget
            productId={productId}
            title={productName}
            priceCurrency={productPrice.currency}
            priceValue={`${productPrice.value}`}
            productUrl={productUrl || ''}
            imageUrl={imageUrl}
        />
    );
};

export default YotpoReviewWidgetWrapper;
