'use client';

import React, { createContext, ReactElement, useState } from 'react';
import type { IProductDetailsSectionContext } from '../types';

export const ProductDetailsSectionContext = createContext<IProductDetailsSectionContext>(
    {} as IProductDetailsSectionContext,
);

export const ProductDetailsSectionProvider = ({ children }: { children: ReactElement }) => {
    const [activeIndex, setActiveIndex] = useState<number>(-1);

    return (
        <ProductDetailsSectionContext.Provider value={{ activeIndex, setActiveIndex }}>
            {children}
        </ProductDetailsSectionContext.Provider>
    );
};
