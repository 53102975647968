'use client';

import React, { useContext } from 'react';
import { Typography } from '@vaimo-int/aem-pwa-common-components';
import SizeFitIcon from './assets/sizeFit';
import { YotpoFitSizeInfoEnum } from '@/integrations/yotpo/src/types';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';
import classes from '@/components/RootComponents/Product/styles/product.module.scss';

const YotpoFitSize = ({
    bigSize,
    sizeInfoBaseText,
    sizeInfoText,
    smallSize,
    trueToSize,
}: {
    bigSize: string;
    sizeInfoBaseText: string;
    sizeInfoText: string;
    smallSize: string;
    trueToSize: string;
}) => {
    const { fitSizeInfo } = useContext(ProductContext);
    if (!fitSizeInfo || !Object.values(YotpoFitSizeInfoEnum).includes(fitSizeInfo)) {
        return;
    }

    const yotpoFitSizeVariants = {
        1: {
            baseTextDefaultMessage: 'Customers say the fit ',
            baseTextId: sizeInfoBaseText,
            fitSizeTypeTextDefaultMessage: "'Runs small'",
            fitSizeTypeTextId: smallSize,
        },
        2: {
            baseTextDefaultMessage: 'Customers say the fit is ',
            baseTextId: sizeInfoText,
            fitSizeTypeTextDefaultMessage: "'True to size'",
            fitSizeTypeTextId: trueToSize,
        },
        3: {
            baseTextDefaultMessage: 'Customers say the fit ',
            baseTextId: sizeInfoBaseText,
            fitSizeTypeTextDefaultMessage: "'Runs large'",
            fitSizeTypeTextId: bigSize,
        },
    };

    const yotpoFitSizeProps = yotpoFitSizeVariants[fitSizeInfo];

    return (
        <div className={classes.yotpoFitSizeContainer}>
            <Typography.Body className={classes.yotpoFitSize}>
                <SizeFitIcon />
                {yotpoFitSizeProps.baseTextId}
                <Typography.Body style={'bold'} className={classes.yotpoFitSizeInfo}>
                    {yotpoFitSizeProps.fitSizeTypeTextId}
                </Typography.Body>
            </Typography.Body>
        </div>
    );
};

export default YotpoFitSize;
