import { IGetBreadcrumbs, IGetBreadcrumbsProps } from '@/components/Breadcrumbs/types';
import type { ICategoryBreadcrumbs } from '@/types/category';

// Just in case the data is unsorted, lets sort it.
const sortCrumbs = (a: any, b: any) => a.category_level - b.category_level;

// Generates the path for the category.
const getPath = (path: string, suffix: string) => (path ? `/${path}${suffix || ''}` : '#');

export const getBreadcrumbs = ({
    categoryData,
    categoryUrlSuffix,
    currentProduct,
    items,
}: IGetBreadcrumbsProps & { categoryUrlSuffix?: string | null }): Array<IGetBreadcrumbs> => {
    if (currentProduct && !categoryData) {
        return [{ text: currentProduct }];
    }

    if (items) return items;

    if (!categoryData) return [];

    const { breadcrumbs, name, url_path } = categoryData;

    const links =
        breadcrumbs
            ?.map((category: ICategoryBreadcrumbs) => ({
                category_level: category.category_level,
                path: getPath(category.category_url_path, categoryUrlSuffix || ''),
                text: category.category_name,
            }))
            .sort(sortCrumbs) || [];

    const restItems = currentProduct
        ? [
              {
                  path: `${url_path}${categoryUrlSuffix || ''}`,
                  text: name,
              },
              { text: currentProduct },
          ]
        : [{ text: name }];

    return [...links, ...restItems];
};
