'use client';

import React, { useContext } from 'react';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';
import FormError from '@/components/FormError';

const SubmitErrors = () => {
    const { errors } = useContext(ProductContext);

    return <FormError errors={errors} />;
};

export default SubmitErrors;
