'use client';

import React, { useContext } from 'react';
import Price from '@/components/Price';
import { Notification, Tooltip, EmbeddedHtml, Icon } from '@vaimo-int/aem-pwa-common-components';
import { useTranslations } from 'next-intl';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';
import classes from './styles/lowestPrice.module.scss';

const LowestPrice = () => {
    const { hasDiscount, lowestPriceData } = useContext(ProductContext);
    const t = useTranslations('productLowestPrice');

    const {
        campaign_content: campaignContent,
        campaign_title: campaignTitle,
        lowest_price: lowestPrice,
    } = lowestPriceData || {};

    if (!lowestPriceData?.lowest_price || !hasDiscount) return null;

    return (
        <>
            <div className={classes.lowestPrice}>
                <span className={classes.lowestPriceText}>
                    {t('lowestPrice')}
                    &nbsp;
                    <Price value={lowestPrice.value} currencyCode={lowestPrice.currency} />
                </span>
                <Tooltip
                    className={classes.lowestPriceTooltip}
                    // TODO fix mobile
                    // position={isMobile && 'bottomCenter'}
                    text={t('tooltipText')}
                >
                    <Icon name={'information'} size={'16px'} />
                </Tooltip>
            </div>
            {campaignContent && (
                <div className={classes.lowestPriceNotification}>
                    <Notification.Inline
                        customIcon="loyalty"
                        header={campaignTitle}
                        text={<EmbeddedHtml html={campaignContent} />}
                    />
                </div>
            )}
        </>
    );
};

export default LowestPrice;
