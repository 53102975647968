'use client';

import React, { useContext, useState, useRef, useEffect } from 'react';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';
import GalleryItem from '@/components/Gallery/modules/GalleryItem/galleryItem';
import { MediaGalleryEntry, MediaGalleryTypeEnum } from '@/types/product';
import PaginationDots from '@/components/Gallery/modules/PaginationDots/paginationDots';
import classes from './styles/gallery.module.scss';

const Gallery = ({ productName }: { productName: string }) => {
    const { mediaGalleryEntries, optionSelections } = useContext(ProductContext);
    const [currentSlide, setCurrentSlide] = useState(0);
    const imageContainerRef = useRef<HTMLDivElement>(null);
    const selectedColor = optionSelections.get(optionSelections.keys().next().value);

    const mainImage = mediaGalleryEntries[0];
    const filteredImages = ((): Array<MediaGalleryEntry> => {
        if (!mediaGalleryEntries || mediaGalleryEntries.length <= 1) return [];
        let videoItem: MediaGalleryEntry | undefined;
        const filteredMap = mediaGalleryEntries.slice(1).reduce(
            (accumulated, current) => {
                if (current.media_type === MediaGalleryTypeEnum.IMAGE && current.file) {
                    accumulated.set(current.file, current);
                } else if (current.media_type === MediaGalleryTypeEnum.VIDEO && !videoItem) {
                    videoItem = current;
                }

                return accumulated;
            },
            new Map() as Map<string, MediaGalleryEntry>,
        );

        const filteredElements = [...filteredMap.values()];
        // Video should always have 4th position
        if (videoItem) {
            if (filteredElements.length > 3) {
                filteredElements.splice(3, 0, videoItem);
            } else {
                filteredElements.push(videoItem);
            }
        }

        // We should show maximum 16 thumbnails
        return filteredElements.slice(0, 16);
    })();

    const handleSlideChange = (newSlide: number) => {
        if (currentSlide !== newSlide) {
            setCurrentSlide(newSlide);
        }
    };

    useEffect(() => {
        imageContainerRef.current?.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
    }, [selectedColor, imageContainerRef]);

    return (
        <div className={classes.wrapper}>
            <div className={classes.imageContainer} ref={imageContainerRef}>
                <GalleryItem
                    isMainImage
                    {...mainImage}
                    onInView={() => handleSlideChange(0)}
                    productName={productName}
                />
                {filteredImages.map((item, idx) => (
                    <GalleryItem
                        key={item.file}
                        {...item}
                        onInView={() => handleSlideChange(idx + 1)}
                        productName={productName}
                    />
                ))}
            </div>
            <PaginationDots currentSlide={currentSlide} totalLength={filteredImages.length + 1} />
        </div>
    );
};

export default Gallery;
