'use client';

import React, { ReactElement, useContext } from 'react';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';
import PayPalMessage from './PayPalMessage';

const PayPalProductMessageWrapper = (): ReactElement | null => {
    const { productPrice } = useContext(ProductContext);

    return <PayPalMessage amount={productPrice.minPrice} pageType={'product-details'} logoType={'primary'} />;
};

PayPalProductMessageWrapper.displayName = 'PayPalProductMessageWrapper';

export default PayPalProductMessageWrapper;
