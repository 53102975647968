'use client';

import React from 'react';
import { useProductSchema } from './hooks/useProductSchema';
import { IProductDetails } from '@/components/SEO/ProductSchema/types';

interface IProductSchema {
    baseLinkUrl: string;
    productDetails: IProductDetails;
}

const ProductSchema = ({ baseLinkUrl, productDetails }: IProductSchema) => {
    const productSchemaJSON = useProductSchema(productDetails, baseLinkUrl);

    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: productSchemaJSON }}></script>;
};

export default ProductSchema;
