import React from 'react';
import clsx from 'clsx';
import classes from './styles/paginationDots.module.scss';

interface IPaginationDots {
    currentSlide: number;
    totalLength: number;
}

const PaginationDots = ({ currentSlide, totalLength }: IPaginationDots) => {
    return (
        <div className={classes.wrapper}>
            {new Array(totalLength).fill(1).map((item, idx) => (
                <div key={idx} className={clsx(classes.item, currentSlide === idx && classes.itemActive)} />
            ))}
        </div>
    );
};

export default PaginationDots;
