'use client';

import React, { useContext } from 'react';
import Link from 'next/link';
import { Typography } from '@vaimo-int/aem-pwa-common-components';
import { YotpoRating as YotpoRatingComponent } from '@/integrations/yotpo';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';
import { IYotpoRating } from '@/integrations/yotpo/src/components/YotpoRating/types';

const YotpoRating = ({ writeReviewText }: { writeReviewText: string }) => {
    const { rating } = useContext(ProductContext);

    return !rating?.total ? (
        <Typography.Body>
            <Link href={'#writeReview'}>{writeReviewText}</Link>
        </Typography.Body>
    ) : (
        <YotpoRatingComponent hasTotalCount isShowEmptyStars isProductPage yotpoRating={rating as IYotpoRating} />
    );
};

export default YotpoRating;
