'use client';

import React, { Suspense, lazy, useContext } from 'react';
import { FiHeart as HeartIcon } from 'react-icons/fi';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';
import { Button2 } from '@vaimo-int/aem-pwa-common-components';
import { useAddToListButton } from './hooks/useAddToListButton';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';
import defaultClasses from './wishlistButton.module.scss';

const ConfirmLoginModal = lazy(() => import('./modules/ConfirmLoginModal'));

const HeartIconElement = ({ item }: { item: number }) => {
    return (
        <span className={clsx(defaultClasses.heartIcon, defaultClasses[`heartIcon${item}`])}>
            <HeartIcon />
        </span>
    );
};

const WishlistButton = () => {
    const { wishlistButtonProps } = useContext(ProductContext);
    const {
        buttonProps,
        isAddingToWishlist,
        isConfirmingModalOpen,
        isRemovingFromWishlist,
        isSelected,
        isSignedIn,
        isWishlistButtonDisabled,
        setIsConfirmingModalOpen,
        // @ts-expect-error force JS to TS
    } = useAddToListButton(wishlistButtonProps);

    const pathname = usePathname();

    const rootClassName = clsx(defaultClasses.root, {
        [defaultClasses.rootAdding]: isAddingToWishlist,
        [defaultClasses.rootIsActive]: isSignedIn,
        [defaultClasses.rootRemoving]: isRemovingFromWishlist,
        [defaultClasses.rootSelected]: isSelected,
    });

    const animationElements = [1, 2, 3].map((i) => <HeartIconElement key={i} item={i} />);

    return (
        <>
            <Button2 variant={'outlined'} disabled={isWishlistButtonDisabled} {...buttonProps} classes={rootClassName}>
                {animationElements}
                <HeartIcon className={defaultClasses.addToListButtonIcon} />
            </Button2>
            <Suspense fallback={null}>
                <ConfirmLoginModal
                    isOpen={isConfirmingModalOpen}
                    actionAfterLogin={{ action: 'addToWishlist', path: pathname }}
                    setIsOpen={setIsConfirmingModalOpen}
                />
            </Suspense>
        </>
    );
};

export default WishlistButton;
