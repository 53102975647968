import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import classes from './styles/video.module.scss';

interface VideoProps {
    autoplay: boolean;
    className?: string;
    controls: boolean;
    muted: boolean;
    playTimes?: number;
    url: string;
    width?: number;
}

const Video = ({ autoplay, className, controls, muted, playTimes, url, width }: VideoProps) => {
    const [playedTimes, setPlayedTimes] = useState(0);

    const videoRef = useRef<HTMLVideoElement>(null);

    const handleVideoEnded = (): void => {
        if (!autoplay || !playTimes || playedTimes >= playTimes - 1) return;
        videoRef.current?.play();
        setPlayedTimes((prevState) => prevState + 1);
    };

    return (
        <div className={clsx(classes.root, className)}>
            <video
                ref={videoRef}
                src={url}
                autoPlay={autoplay}
                muted={muted}
                controls={controls}
                width={width}
                onEnded={handleVideoEnded}
                playsInline
            />
        </div>
    );
};

export default Video;
