import { MouseEventHandler, MutableRefObject, useState } from 'react';
import throttle from 'lodash.throttle';
import useIsMobile from '@/hooks/useIsMobile';
import classes from '../zoom.module.scss';

interface IUseZoom {
    imgRef: MutableRefObject<HTMLImageElement | null>;
    wrapperRef: MutableRefObject<any>;
    zoomRatio: number;
}

interface IInitializationValues {
    boundsLeft: number;
    boundsTop: number;
    sourceHeight: number;
    sourceWidth: number;
    zoomRatioX: number;
    zoomRatioY: number;
}

const THROTTLE_TIMING = 10;

const useZoom = ({ imgRef, wrapperRef, zoomRatio }: IUseZoom) => {
    const [initializationValues, setInitializationValues] = useState<IInitializationValues | undefined>(undefined);
    const isMobile = useIsMobile();

    const handleMove = throttle((event) => {
        const imgRefCurrent = imgRef.current;

        if (!imgRefCurrent || !initializationValues) return;

        const xMove = event.clientX - initializationValues.boundsLeft;
        const yMove = event.clientY - initializationValues.boundsTop;

        const left = xMove - initializationValues.sourceWidth / 2;
        const top = yMove - initializationValues.sourceHeight / 2;

        imgRefCurrent.style.left = left * -initializationValues.zoomRatioX + 'px';
        imgRefCurrent.style.top = top * -initializationValues.zoomRatioY + 'px';
    }, THROTTLE_TIMING);

    const handleClose = () => {
        const imgRefCurrent = imgRef.current;

        if (!imgRefCurrent) return;

        setTimeout(() => {
            setInitializationValues(undefined);
            imgRefCurrent.style.left = '';
            imgRefCurrent.style.top = '';
            imgRefCurrent.classList.remove(classes.active);
            imgRefCurrent.style.transform = '';
        }, THROTTLE_TIMING + 1);
    };

    const handleInit: MouseEventHandler = () => {
        const wrapperRefCurrent = wrapperRef.current;
        if (!wrapperRefCurrent || !imgRef.current || isMobile) return;

        if (initializationValues) {
            handleClose();

            return;
        }

        const bounds = wrapperRef.current.getBoundingClientRect();
        const wrapperOffsetHeight = wrapperRefCurrent.offsetHeight;
        const wrapperOffsetWidth = wrapperRefCurrent.offsetWidth;

        setInitializationValues({
            boundsLeft: bounds.left,
            boundsTop: bounds.top,
            sourceHeight: wrapperOffsetHeight,
            sourceWidth: wrapperOffsetWidth,
            zoomRatioX: (wrapperOffsetWidth * zoomRatio - wrapperOffsetWidth) / wrapperOffsetWidth,
            zoomRatioY: (wrapperOffsetHeight * zoomRatio - wrapperOffsetHeight) / wrapperOffsetHeight,
        });
        imgRef.current.classList.add(classes.active);
        imgRef.current.style.transform = `scale(${zoomRatio})`;
    };

    const onMouseMove: MouseEventHandler = (event) => {
        if (!initializationValues) return;

        handleMove(event);
    };

    const onMouseLeave: MouseEventHandler = () => {
        if (initializationValues) {
            handleClose();
        }
    };

    return {
        handleInit,
        onMouseLeave,
        onMouseMove,
    };
};

export default useZoom;
