import React, { useCallback, useRef } from 'react';
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';
import OptimizedImage from '@/components/OptimizedImage';
import { UNCONSTRAINED_SIZE_KEY } from '@/components/OptimizedImage/utils/helpers';
import useIsMobile from '@/hooks/useIsMobile';
import useZoom from '@/components/Gallery/modules/Zoom/hooks/useZoom';
import Video from '@/components/Video';
import { getVideoLinkFromMetaData } from '@/components/Gallery/modules/GalleryItem/utils/getVideoLinkFromMetaData';
import { MediaGalleryEntry, MediaGalleryTypeEnum } from '@/types/product';
import { ImageWidthsEnum } from '@/components/OptimizedImage/types';
import { PRODUCT_IMAGE_URL_PREFIX } from '@/components/OptimizedImage/utils/prefixes';
import classes from './styles/galleryItem.module.scss';
import classesZoom from '../Zoom/zoom.module.scss';

const IMAGE_SIZE = 900;
const IMAGE_WIDTHS = new Map()
    .set(ImageWidthsEnum.LARGE, ImageWidthsEnum.LARGE)
    .set(IMAGE_SIZE, IMAGE_SIZE)
    .set('3x', ImageWidthsEnum.XLARGE);
const IMAGE_SIZE_SET = new Map().set(767, 100).set(1800, 50).set(UNCONSTRAINED_SIZE_KEY, IMAGE_SIZE);
const VIDEO_PLAY_TIMES = 3;

interface IGalleryItem extends MediaGalleryEntry {
    isMainImage?: boolean;
    onInView: () => void;
    productName: string;
}

const GalleryItem = ({ file, isMainImage, label, media_type, onInView, productName, video_content }: IGalleryItem) => {
    const isMobile = useIsMobile();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    const imgURL = new URL(PRODUCT_IMAGE_URL_PREFIX + file, process.env.MAGENTO_BACKEND_URL);
    imgURL.searchParams.set('height', String(IMAGE_SIZE));

    const { ref: inViewRef } = useInView({
        onChange: (inView) => {
            if (inView) {
                onInView();
            }
        },
        skip: !isMobile,
        threshold: 0.9,
    });

    const sharedRef = useCallback(
        (node: HTMLDivElement) => {
            // @ts-expect-error Ref's from useRef needs to have the node assigned to `current`
            wrapperRef.current = node;
            // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
            inViewRef(node);
        },
        [inViewRef],
    );

    const { handleInit, onMouseLeave, onMouseMove } = useZoom({ imgRef, wrapperRef, zoomRatio: 2 });

    return (
        <div
            ref={sharedRef}
            className={clsx(classes.wrapper, isMainImage && classes.mainImage, classesZoom.parent)}
            onMouseLeave={onMouseLeave}
            onMouseMove={onMouseMove}
            onClick={handleInit}
        >
            {media_type === MediaGalleryTypeEnum.IMAGE && (
                <OptimizedImage
                    ref={imgRef}
                    isImgTag
                    isSetWidth={false}
                    className={classesZoom.img}
                    loading={!isMainImage ? 'lazy' : undefined}
                    fetchPriority={isMainImage ? 'high' : undefined}
                    widths={IMAGE_WIDTHS}
                    sizesSet={IMAGE_SIZE_SET}
                    src={imgURL.toString()}
                    alt={label || productName}
                />
            )}
            {media_type === MediaGalleryTypeEnum.VIDEO && (
                <Video
                    url={getVideoLinkFromMetaData(video_content?.video_metadata)}
                    className={classes.videoBlock}
                    muted={true}
                    autoplay={false}
                    controls={true}
                    playTimes={VIDEO_PLAY_TIMES}
                />
            )}
        </div>
    );
};

export default GalleryItem;
