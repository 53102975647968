'use client';

import React, { ReactElement } from 'react';
import { FiChevronRight as ChevronRightIcon } from 'react-icons/fi';
import Dialog from '@/components/Dialog';
import { Typography } from '@vaimo-int/aem-pwa-common-components';
import useProgressItem from './hooks/useProgressItem';
import RichContent from '@/components/RichContent';

import classes from './productPerformance.module.scss';

interface ProgressItemProps {
    content: string;
    heading: string;
    isABTestsEnabled: boolean;
    maxValue: number;
    value: number;
}

const ProgressItem = ({ content, heading, isABTestsEnabled, maxValue, value }: ProgressItemProps): ReactElement => {
    const { handleClick, isOpen } = useProgressItem();

    const progressItemHeading = content ? (
        <span className={classes.progressItemHeading}>
            {heading}
            <ChevronRightIcon className={classes.productPerformanceChevron} />
        </span>
    ) : (
        heading
    );

    const newProgressItemHeading = (
        <Typography.Body className={content ? classes.newProgressItemHeading : ''}>{heading}</Typography.Body>
    );

    return isABTestsEnabled ? (
        <>
            <div
                className={content ? classes.newProgressItemWithContent : classes.newProgressItem}
                onClick={handleClick}
            >
                <div className={classes.newProgressItemTitle}>
                    {newProgressItemHeading}
                    <Typography.Body>
                        {value}/{maxValue}
                    </Typography.Body>
                </div>
                <progress max={maxValue} value={value} className={classes.newProgressItemBar} />
            </div>
            {content && (
                <Dialog
                    isOpen={isOpen}
                    onCancel={handleClick as any}
                    shouldShowButtons={false}
                    title={heading}
                    shouldCancelOnOutsideClick
                    shouldUnmountOnHide
                >
                    <RichContent html={content} />
                </Dialog>
            )}
        </>
    ) : (
        <>
            <div className={content ? classes.progressItemWithContent : classes.progressItem} onClick={handleClick}>
                <div className={classes.progressItemTitle}>
                    {progressItemHeading}
                    <span>
                        {value}/{maxValue}
                    </span>
                </div>
                <progress max={maxValue} value={value} className={classes.progressItemBar} />
            </div>
            {content && (
                <Dialog
                    isOpen={isOpen}
                    onCancel={handleClick as any}
                    shouldShowButtons={false}
                    title={heading}
                    shouldCancelOnOutsideClick
                    shouldUnmountOnHide
                >
                    <RichContent html={content} />
                </Dialog>
            )}
        </>
    );
};

export default ProgressItem;
