import { MouseEvent, useCallback, useState } from 'react';

interface IUseProgressItem {
    handleClick(event: MouseEvent<HTMLElement>): void;
    isOpen: boolean;
}

const useProgressItem = (): IUseProgressItem => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleClick = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            event.preventDefault();

            setIsOpen((prevState) => !prevState);
        },
        [setIsOpen],
    );

    return { handleClick, isOpen };
};

export default useProgressItem;
