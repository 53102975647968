'use client';

import React, { useContext } from 'react';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';
import { Typography } from '@vaimo-int/aem-pwa-common-components';
import classes from './styles/freeShipping.module.scss';

const FreeShipping = ({ text }: { text: string }) => {
    const { isFreeShipping } = useContext(ProductContext);

    return isFreeShipping ? (
        <div className={classes.productFreeShippingLabel}>
            <Typography.Body>{text}</Typography.Body>
        </div>
    ) : null;
};

export default FreeShipping;
